.sports {
  .sports-list {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    align-items: center;
    justify-content: space-between;

    @include respond-to(upto-sm) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include respond-to(xxs) {
      grid-template-columns: repeat(2, 1fr);
    }

    .sport {
      display: flex;
      flex-direction: column;
      align-items: center;
      .image {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 80%;
        }
      }
    }
  }
}

.reviews {
  .reviews-wrapper {
    position: relative;
    .pattern {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 300px;
      z-index: 3;
    }
  }

  .reviews-carousel {
    position: relative;
    top: -30px;
    .item {
      display: grid;
      grid-gap: 50px;
      grid-template-columns: 1fr 1fr;

      > div {
        padding: 50px 0;
      }
      .info {
        align-self: end;
        justify-self: start;
        padding-right: 30px;
        .text {
          font-size: 20px;
          background-color: $accent_color;
        }
        .user {
          font-size: 14px;
        }
      }
      .image {
        padding-top: 70px;
        align-self: start;
        justify-self: center;

        position: relative;

        .club {
          position: absolute;
          border-radius: 50%;
          top: 80px;
          z-index: 2;
          width: 130px;
          height: 130px;
          left: -65px;
          background-color: #fff;
          padding: 10px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          > img {
            width: 100px;
          }
        }

        img {
          max-width: 100%;

          &.user {
            width: 250px;
            height: 250px;
            position: relative;
            border-radius: 50%;
            left: 0px;
            top: 50px;
            z-index: 1;
            border: 5px solid #fff;
          }
        }

        @include respond-to(md) {
          img {
            &.club {
              width: 100px;
              height: 100px;
              right: -80px;
            }
          }
        }
      }
    }
  }

  @include respond-to(upto-sm) {
    .header {
      max-width: 100%;
    }
    .reviews-wrapper {
      .reviews-carousel {
        top: 0;
        .item {
          grid-template-columns: 1fr;

          .image {
            display: none;
          }
          .info {
            .text {
              font-size: 18px;
            }
          }
        }
      }
      .pattern {
        bottom: -80px;
        right: -15px;
        width: 200px;
      }
    }
  }

  &._white {
    .text-white {
      color: $black !important;
    }

    .text {
      background-color: $white !important;
    }
  }
}

.owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: -50px;

  .owl-dot {
    width: 8px;
    height: 8px;
    background: $dark_white !important;
    border-radius: 50%;
    margin: 0 2px;

    &.active {
      background: $primary_color !important;
    }

    &:focus {
      outline: none !important;
      border: none !important;
    }
  }
}

.owl-nav {
  > button {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px !important;
    outline: none !important;

    &:first-child {
      left: -50px;
    }

    &:last-child {
      right: -50px;
    }
  }

  @include respond-to(upto-md) {
    display: none;
  }
}

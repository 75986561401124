.work {
  .value {
    border-top: 1px solid $grey;
    padding: 15px 0;
    .job {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0;
        font-size: 18px;
        font-weight: $font_medium;
        font-family: "stratos", sans-serif;
      }
    }

    .description {
      margin-top: 10px;
      font-size: 15px;
      p {
        font-size: 15px;
        margin: 0;
      }
      ul > li {
        font-size: 15px;
      }
    }
    .description {
      display: none;
    }
  }
}

.checkbox-wrapper {
  margin: 20px 0;
  //   &.is-invalid {
  //     .checkmark {
  //       border-color: $danger_color;
  //       border: 1px solid;
  //     }
  //     label {
  //       color: $danger_color;
  //     }
  //   }

  p {
    margin-bottom: 0;
  }

  > label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 12px;
    color: $light_grey;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
    height: 25px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:disabled {
        & ~ .checkmark {
          background-color: #ccc !important;
        }
      }
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      border-width: 0;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background-color: $lighter_grey;
      transition: background-color 0.2s;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
      background-color: $primary_color;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      left: 8px;
      top: 5px;
      width: 5px;
      height: 9px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  label.error {
    margin-top: 10px;
    font-size: 12px;
    color: $primary_color;
  }
}

.other_products {
  .container {
    .product {
      a {
        cursor: pointer;
        display: block;

        > h2 {
          color: $black;
        }

        > .image {
          > img {
            width: 100%;
          }
        }

        > p {
          margin: 0 auto;
          width: 80%;
        }

        &:hover {
          > h2 {
            color: $black;
          }
          > p {
            color: $black;
          }
        }
      }
    }
  }
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;

  thead {
    tr {
      th {
        padding: 5px;
      }
    }
  }

  tbody {
    tr {
      td {
        border-top: 1px solid #eee;
        padding: 12px 5px;

        h1,
        h2,
        h3,
        h4 {
          margin: 0;
        }

        @include respond-to(upto-sm) {
          font-size: 14px;
        }
      }
    }
  }

  &.plans-table {
    @include respond-to(xxs) {
      width: 460px;
    }

    th,
    td {
      text-align: center;
    }

    thead > tr {
      > th {
        width: calc(60% / 4);
        &:first-child {
          width: 40%;
        }

        @include respond-to(upto-sm) {
          width: calc(50% / 4);
          &:first-child {
            width: 50%;
          }
        }
      }
    }

    th > div {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      img {
        height: 24px;
      }
      h4 {
        margin: 0;
      }

      @include respond-to(upto-sm) {
        flex-direction: column;
        img {
          height: 18px;
        }
        h4 {
          font-size: 14px;
        }
      }
    }

    tbody {
      th:first-child,
      td:first-child {
        text-align: left;
      }
    }
  }
}

.plans-table-wrapper {
  overflow-x: auto;
  margin: 0 -15px;
  padding: 0 15px;
}

.table-fixed-header {
  position: fixed;
  top: 60px;
  display: none;
  background-color: white;
  width: 100%;
}

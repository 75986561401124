.partners {
  .image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 70%;
    }

    @include respond-to(upto-sm) {
      img {
        max-width: 100%;
      }
    }
  }
}

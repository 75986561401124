.product-features {
  .product-features-header {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        margin: 0;
      }

      .menu {
        a {
          padding: 0 20px;
          border-right: 1px solid $lighter_grey;
          &:last-child {
            border-right: none;
            padding-right: 0;
          }
        }
      }

      @include respond-to(upto-sm) {
        display: block;
        .menu {
          margin-top: 20px;
          // display: none;
          a {
            display: block;
            padding: 10px 0;
            border-right: none;
            border-top: 2px solid $lighter_grey;
          }
        }
      }
    }

    @include respond-to(from-md) {
      &.fixed {
        position: fixed;
        top: $header_height;
        z-index: 3;
        width: 100%;
        border-bottom: 1px solid $lighter_grey;
      }
    }
  }
  .product-features-content {
    .features {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;

      &.columns-2 {
        grid-template-columns: repeat(2, 1fr);
      }

      @include respond-to(sm) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include respond-to(xs) {
        grid-template-columns: 1fr !important;
      }

      .feature {
        h3 {
          @include respond-to(from-md) {
            font-size: 24px;
          }
        }

        p {
          font-size: 15px;
        }

        .image {
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;

          img {
            width: 95px;
          }
        }
      }
    }

    .image {
      img {
        margin: 0 auto;
        display: block;
        max-width: 100%;
      }
    }

    .video {
      @include respond-to(upto-sm) {
        
      }
    }
  }
}

#golee-manager-features-button {
  + .features-wrapper {
    display: none;
    margin-top: -80px;
  }
}

.plans {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 30px;

  .plan {
    padding: 20px;
    border-radius: 8px;
    width: auto;
    max-width: 100%;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.15) 0px 1px 2px -1px;

    &.highlight {
      box-shadow: rgba(250, 103, 97, 0.6) 0px 1px 5px 5px;
    }

    .price {
      .old-price {
        color: rgb(167, 167, 167);
        text-decoration: line-through;
        font-weight: $font_light !important;
        font-size: 90%;
      }

      small {
        color: $grey;
        font-weight: $font_light;
        font-size: 16px;
      }
    }

    .plan-header {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      > div {
        display: flex;
        align-items: baseline;
        img {
          height: 25px;
          margin-right: 15px;
        }
      }

      .highlight {
        color: $primary_color;
        text-transform: uppercase;
      }
    }

    .features {
      border-top: 1px solid #eee;
      margin: 20px 0 0;
      padding: 20px 0 0;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        font-weight: $font_light;
        font-size: 14px;

        svg {
          margin-right: 10px;
        }
      }
    }
  }

  @include respond-to(upto-sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond-to(xs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.societies {
  .images {
    .item {
    }

    + .row {
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 0;
        &:not(:last-child) {
          border-right: 1px solid $grey;
        }
        > h3 {
          margin: 0 auto;
          width: 80%;
        }
      }
    }
  }
}

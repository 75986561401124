.btn {
  &:active,
  &:focus,
  &:hover {
    outline: none !important;
    box-shadow: none !important;
  }

  font-family: "stratos", sans-serif;
  font-size: 18px;
  border-radius: 8px;
  border: none;
  text-align: center;
  padding: 10px;
  min-width: 180px;

  @include respond-to(xs) {
    min-width: 180px;
  }

  &.btn-xs {
    min-width: 150px;
    padding: 8px 10px;
    font-size: 15px;
  }

  &.btn-primary {
    background-color: $primary_color;
    color: $white;

    &:not(.disabled),
    &:not(:disabled) {
      &:active,
      &:focus,
      &:hover {
        background-color: $primary_color_hover;
        color: $white;
      }
    }

    &.btn-outline {
      background-color: $white;
      color: $primary_color;

      &:active,
      &:focus,
      &:hover {
        background-color: $white_hover;
        color: $primary_color;
      }
    }
  }
}

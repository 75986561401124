section {
  @each $name, $value in $colors {
    &.#{$name} {
      background-color: $value;
      color: $black;
    }
  }

  &.closable {
    .container {
      position: relative;
      span.close {
        cursor: pointer;
        width: 30px;
        height: 30px;
        line-height: 30px;
        position: absolute;
        right: -20px;
        top: 0;
        color: $white;
        border-radius: 50%;
        background-color: $light_grey;

        i {
          font-size: 20px;
          top: -2px;
          position: relative;
        }
      }
    }
  }

  &._primary,
  &._red,
  &._dark {
    color: $white;
  }

  &._beige_darkbeige {
    background: linear-gradient(90deg, $beige 50%, $dark_beige 50%);

    @include respond-to(upto-sm) {
      background: $beige;
    }
  }

  &.image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 20%;
    min-height: 150px;

    &.golee-coach {
      background-image: url("../img/golee-coach/img-banner.jpg");
    }
    &.golee-store {
      background-image: url("../img/golee-store/img-banner.jpg");
    }
    &.golee-web {
      background-image: url("../img/golee-web/img-banner.jpg");
    }
    &.golee-manager-football {
      background-image: url("../img/golee-manager/sports/football_footer.jpeg");
    }
    &.golee-manager-basket {
      background-image: url("../img/golee-manager/sports/basket_footer.jpeg");
    }
    &.golee-manager-volley {
      background-image: url("../img/golee-manager/sports/volley_footer.jpeg");
    }

    @include respond-to(xs) {
      padding-top: 70%;
    }
  }

  &.pattern-plus {
    background-image: url("../img/bg-pattern-plus.png");
    background-size: 1000px;
    background-position: top;
    background-repeat: no-repeat;
  }

  .title {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include respond-to(xs) {
      max-width: 100%;
    }
  }

  // 2 cols layout (Text-image)
  &.section-2-cols {
    &.inverted {
      .container {
        > div.left {
          grid-column: 2;
        }
        > div.right {
          grid-column: 1;
        }
      }

      @include respond-to(upto-sm) {
        .container {
          > div.left {
            grid-column: 1;
          }
        }
      }
    }

    padding: 60px 0;

    .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 150px;
      align-items: center;

      > div {
        grid-row: 1;
      }

      .image {
        img {
          display: block;
          max-width: 100%;
        }
      }

      .content {
        .title {
        }

        .actions {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          a,
          button {
            + a,
            button {
              margin-left: 20px;
            }
          }
          @include respond-to(xxs) {
            justify-content: space-between;

            a,
            button {
              + a,
              button {
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    @include respond-to(upto-sm) {
      .container {
        grid-template-columns: 1fr;
        grid-gap: 60px;

        > div {
          grid-column: 1;

          &:first-child {
            grid-row: 1;
          }
          &:last-child {
            grid-row: 2;
          }
        }

        .image {
          img {
            margin: 0 auto;
          }
        }

        .content {
          background-color: inherit;
        }
      }

      &.inverted-mobile {
        .container {
          > div {
            grid-column: 1;

            &:first-child {
              grid-row: 2;
            }
            &:last-child {
              grid-row: 1;
            }
          }
        }
      }

      @each $name, $color in $colors {
        &.#{$name} {
          background: $color;

          &.inverted {
            background: $color;
          }
        }
      }
    }

    &.section-2-cols-flex {
      padding: 80px 0;
      .container {
        display: flex;
        align-items: center;
        gap: 150px;

        > div {
          flex: 0.5;
        }
      }

      @include respond-to(upto-sm) {
        .container {
          display: block;
          > div:last-child {
            margin-top: 40px;
          }
        }
      }
    }
  }

  // 2 cols layout (only text)
  &.section-2-cols-text {
    padding: 100px 0;

    .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 150px;

      > div {
        grid-row: 1;
      }

      .content {
        .text {
          font-weight: $font_medium;
          p {
            margin: 0 !important;
            font-weight: $font_light;
          }
          ul {
            font-weight: $font_light;
            padding-left: 20px;
            margin: 5px 10px;
          }
        }
        .actions {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          a,
          button {
            + a,
            button {
              margin-left: 20px;
            }
          }

          @include respond-to(xxs) {
            justify-content: space-between;

            a,
            button {
              + a,
              button {
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    @include respond-to(upto-sm) {
      .container {
        grid-template-columns: 1fr;
        grid-gap: 80px;

        > div {
          grid-column: 1;

          &:first-child {
            grid-row: 1;
          }
          &:last-child {
            grid-row: 2;
          }
        }
      }
    }

    @include respond-to(from-md) {
      .img-responsive {
        max-width: 120% !important;
      }
    }
  }

  // Product intro layout
  &.section-product-intro {
    height: 100vh;
    top: 0;
    left: 0;
    margin-top: -80px;
    padding-top: 120px;
    min-height: 800px;
    overflow: hidden;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .content {
        text-align: center;

        h1 {
          max-width: 70%;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .main-image {
        max-width: 100%;
        position: relative;
        z-index: 1;
      }
    }

    @include respond-to(upto-sm) {
      padding: 40px 0;
      height: auto;
      margin-top: 0;
      min-height: auto;
      .container {
        .content {
          h1 {
            max-width: 100%;
          }
        }
      }
    }

    + section {
      border-top: 60px solid $primary_color;
      position: relative;
      top: -60px;
      margin-bottom: -60px;
    }
  }
}

.text-with-border {
  font-style: italic;
  text-align: center;

  &.left {
    border-left: 3px solid $primary_color;
  }
  &.right {
    border-right: 3px solid $primary_color;
  }
}

// ----------------------------------------------------
// c o l o r s
// ----------------------------------------------------

$red: #fa6761;
$light_blue: #b6d5e2;
$turquoise: #9adada;
$white: #ffffff;
$dark_white: #e2e2e2;
$beige: #f3f3f3;
$dark_beige: #eae5e5;
$darker_grey: #262626;
$dark_grey: #3b3b3b;
$grey: #707070;
$light_grey: #838383;
$lighter_grey: #ddd;
$black: #000;

$green: #d3ddc6;
$orange: #f8d6b4;
$pink: #fef1f1;

$primary_color: $red;
$primary_color_hover: #f9423a;
$white_hover: $pink;
$accent_color: $turquoise;

// Export
$colors: (
  _darkbeige: $dark_beige,
  _darkwhite: $dark_white,
  _red: $red,
  _lightblue: $light_blue,
  _lightgrey: $light_grey,
  _dark: $dark_grey,
  _green: $green,
  _beige: $beige,
  _orange: $orange,
  _primary: $primary_color,
  _turquoise: $turquoise,
  _accent: $accent_color,
  _pink: $pink,
);

// ----------------------------------------------------
// f o n t s
// ----------------------------------------------------
$font_light: 300;
$font_regular: 400;
$font_medium: 500;
$font_bold: 600;
$font_default: $font_regular;

// ----------------------------------------------------
// s i z e s
// ----------------------------------------------------
$header_height: 60px;
$banner_height: 100px;

.golee-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.golee-tooltip .tooltip-text {
  visibility: hidden;
  width: 400px;
  background-color: #333;
  color: #fff;
  border-radius: 8px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
}

.golee-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

@import "../_mixins/responsive";
@import "../variables";

.team-intro {
  height: 46vw;
  position: relative;
  overflow: hidden;

  .team-wrapper {
    top: 0;
    left: 0;
    height: 57vw;
    width: 100%;
    position: absolute !important;
    overflow: hidden;

    img {
      width: 100%;
      height: inherit;
      object-fit: contain;
      object-position: top;
    }
  }
}

.team-section {
  .cards-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @include respond-to(md) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include respond-to(sm) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include respond-to(xs) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include respond-to(xxs) {
      grid-template-columns: 1fr;
    }
  }

  .card {
    overflow: hidden;
    border-radius: 8px;
    border: none;
    position: relative;
    cursor: pointer;

    img {
      max-width: 100%;
      // border-radius: 50%;
    }

    .info {
      padding: 0 10px 10px;
      position: absolute;
      bottom: calc(-100% + 35px);
      height: 100%;
      width: 100%;
      background: #fff;
      transition: bottom 0.4s;
      will-change: bottom;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;

      h6 {
        font-size: 18px;
        line-height: 36px;
        font-weight: 400;
        height: 36px;
      }

      .from {
        position: relative;
        top: -8px;
        color: $light_grey;
        font-size: 12px;
        // border-top: 1px solid $lighter_grey;
        font-style: italic;
      }
      .sub-info {
        position: relative;
        top: -8px;
      }
      .role {
        margin-bottom: 11px;
        font-size: 13px;
        color: $grey;
      }

      .sentence {
        position: relative;
        top: -30px;
        font-size: 13px;
        font-style: italic;
        color: $grey;
      }

      a {
        font-weight: 500;
        font-size: 14px;
        &:hover {
          color: $red !important;
        }
      }
    }
  }

  .card:hover {
    // z-index: 2;
    // transform: scale(1.1);

    .info {
      bottom: 0;
    }
  }
}

@import "../_mixins/responsive";

header#header {
  $apps_menu_height: 160px;

  transition: all 0.5s;
  background-color: $white;
  position: fixed;
  z-index: 6;
  width: 100%;
  top: 0;
  left: 0;
  height: 80px;

  .container {
    opacity: 1;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;

    @include respond-to(from-sm) {
      justify-content: space-between;
    }

    .left,
    .right {
      display: flex;
      align-items: center;
    }

    .menu {
      a:not(#signUpButton) {
        height: $header_height;
        line-height: $header_height;
        color: $black;
        padding: 0 15px;
        display: inline-block;
        border-bottom: 2px solid transparent;

        &#applications-link {
          transition: border 0.2s;
          transition-delay: 0.1s;

          &.bordered {
            border-bottom: 2px solid;
          }
        }

        transition: width 500ms linear 100ms;

        span {
          position: relative;
        }
      }
    }

    :not(.logo) {
      a > span::after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: $primary_color;
        transform: scaleX(0);
        transition: transform 0.3s ease;
      }

      a,
      .selected > span::after {
        transform: scaleX(1);
      }

      a:hover > span::after {
        transform: scaleX(1);
      }
    }

    .logo {
      position: relative;
      margin-right: 40px;
      top: -4px;

      a {
        font-size: 35px;

        &:focus,
        &:active,
        &:hover {
          color: $primary_color;
        }
      }
    }
  }

  &.home {
    background-color: transparent;

    &:hover {
      .menu a:not(.btn),
      i {
        color: $black !important;
      }
    }

    .menu a:not(.btn),
    i {
      color: $black !important;
    }
  }

  &.fixed {
    background-color: $white;
    padding: 0;
    height: 60px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);

    .menu a:not(.btn),
    i {
      color: $black !important;
    }
  }
}

// Mobile header
#mobile-header {
  height: $header_height;
  transition: all 0.5s;
  background-color: transparent;
  position: fixed;
  z-index: 6;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $white;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .icon {
      cursor: pointer;
      color: $black;

      i {
        font-size: 30px;
      }
    }

    .logo {
      a {
        font-size: 30px;
        position: relative;
        top: -4px;

        &:hover {
          color: $primary_color;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .icon {
        margin-left: 15px;
      }
    }
  }

  padding: 0;
  background-color: $white;

  .icon {
    color: $black;
    cursor: pointer;

    i {
      font-size: 30px;
    }
  }

  // }
}

// Mobile menu
.mobile-menu {
  display: none;
  background-color: $white;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;

  .header {
    height: $header_height;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      color: $black;
      cursor: pointer;

      i {
        font-size: 30px;
      }
    }

    .logo {
      position: relative;
      top: 7px;

      a {
        font-size: 30px;
        position: relative;
        top: -11px;

        &:hover {
          color: $primary_color;
        }
      }
    }
  }

  .menu {
    display: block;
    margin-top: 50px;
    margin-bottom: 50px;

    a {
      display: block;
      padding: 10px 0;
      font-size: 20px;
    }

    .auth {
      margin-top: 40px;
    }
  }
}

@include respond-to(from-md) {
  #mobile-header {
    display: none;
  }
}

@include respond-to(upto-sm) {
  header#header {
    display: none;
  }
}

.signup-button-fixed {
  display: none;
}

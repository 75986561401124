div.image {
  &.animation {
    position: relative;

    > img:last-child {
      position: absolute;
      top: 40px;
      left: 0;
      opacity: 0;
      width: 200px;
    }
  }
}

.img-responsive {
  max-width: 100% !important;
}

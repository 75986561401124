@import "../_mixins/responsive";

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #888;
    width: 800px;
    max-width: 90%;
  }

  .close {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 20px;
    background-color: #ddd;
    color: #333;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
}

.promotion-code-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.promotion-code {
  background-color: rgb(245, 245, 245);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 100px;
  border-radius: 20px;
}

#promotion-banner {
  background-color: #000;
  color: #fff;
  width: auto;
  margin: 0px 0px 35px;
  padding: 5px 10px;
  cursor: pointer;

  > div {
    text-align: center;
    padding: 5px 0px;

    > p {
      margin-bottom: 0;
    }
  }

  position: fixed;
  transition: all 0.5s;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 99;

  &.fixed {
    top: 60px;
  }

  @include respond-to(upto-sm) {
    top: 60px;

    + section {
      padding-top: 100px;
    }
  }

  @include respond-to(xs) {
    + section {
      padding-top: 120px;
    }
  }
}

@import "../_mixins/responsive";

footer#footer {
  .main,
  .copy {
    .container {
      display: grid;
      grid-gap: 40px;

      @include respond-to(from-md) {
        grid-template-columns: repeat(6, 1fr);
      }

      @include respond-to(sm) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .main {
    padding-top: 60px;
    padding-bottom: 20px;
    background-color: $darker_grey;
    color: $white;
    font-size: 14px;

    .menu {
      a {
        display: block;
        color: $white;
        &:hover,
        &:active,
        &:focus {
          color: $dark_white;
        }
      }
    }
    .logos {
      height: auto;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-gap: 30px;
      justify-content: center;
      grid-template-columns: repeat(4, max-content);

      .logo {
        display: grid;
        width: 100%;
        height: auto;
        justify-content: center;
        img {
          margin: auto 0;
          opacity: 1;
          visibility: visible;
          height: 65px;
          vertical-align: middle;
          transition: opacity 0.5s;
          @include respond-to(xs) {
            height: 50px;
          }
        }
      }

      @include respond-to(sm) {
        grid-template-columns: repeat(2, max-content);
      }

      @include respond-to(xs) {
        grid-template-columns: repeat(2, max-content);
      }

      @include respond-to(xxs) {
        grid-template-columns: repeat(1, max-content);
      }
    }
  }

  .copy {
    background-color: $dark_grey;
    padding: 20px 0;

    a,
    span {
      font-size: 11px;
      color: $dark_white;
      &:hover,
      &:active,
      &:focus {
        color: $white;
      }
    }

    @include respond-to(xs) {
      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
      }

      a {
        margin-bottom: 10px;
      }
    }
  }
}

#overlay {
  position: fixed;
  top: 0;
  z-index: 20000;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: $white;
  visibility: visible;
  transition: all 1.5s;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 40px;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
  }
}

.home-intro {
  height: 100vh;
  position: relative;
  background: linear-gradient(90deg, $white 30%, $pink 0%);

  .container {
    height: 100%;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .intro {
      color: $black;
      width: 35%;

      h1 {
        font-size: 50px;
      }

      .description {
        font-size: 18px;
      }

      @include respond-to(from-md) {
        margin-top: 30px;
      }
    }

    .image-wrapper {
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 65%;
    }

    @include respond-to(md) {
      .intro {
        width: 40%;
      }
      .image-wrapper {
        width: 60%;
      }
    }
  }

  @include respond-to(upto-sm) {
    height: auto;
    background: $pink;
    padding-top: 100px;
    .container {
      flex-direction: column;

      .intro {
        align-self: flex-start;

        h1 {
          font-size: 36px;
        }

        .description {
          width: 100%;
        }
      }

      .intro,
      .image-wrapper {
        width: 100%;
      }

      .image-wrapper {
        height: 350px;
        margin-top: 30px;
      }
    }
  }
}

.mobile-full-video {
  @include respond-to(upto-sm) {
    padding: 0 !important;

    .container {
      padding: 0 !important;
    }
  }
}

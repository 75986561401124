.milestones {
  .milestones-list {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;

    .milestone {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        height: 70px;
        width: 70px;
        margin-bottom: 10px;
      }

      .title {
        display: flex;
        justify-content: center;
        align-items: baseline;

        @include respond-to(from-md) {
          height: 60px;
        }
      }

      p {
        text-transform: uppercase;
      }
    }
  }

  @include respond-to(upto-sm) {
    .milestones-list {
      grid-template-columns: 1fr 1fr;
    }
  }
}

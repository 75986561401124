// Layout (for sticky footer)
html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Avoid the IE 10-11 `min-height` bug. */

  > .content {
    flex: 1 0 auto; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */

    &:not(.home) {
      @include respond-to(from-md) {
        padding-top: #{$header_height + 20px};
      }

      @include respond-to(upto-sm) {
        padding-top: 60px;
      }
    }
  }

  #footer {
    flex-shrink: 0; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  }
}

// Reset bootstrap
.container {
  @media screen and (min-width: 576px) and (max-width: 767px) {
    max-width: none;
  }

  @include respond-to(upto-md) {
    max-width: none;
  }

  @include respond-to(lg) {
    max-width: 1180px;
  }

  @media screen and (min-width: 1300px) {
    max-width: 1280px;
  }

  @include respond-to(xlg) {
    max-width: 1400px;
  }
}

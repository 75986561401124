.faq {
  .block-title {
    text-decoration-line: underline;
    &-index {
      margin-top: 50px;
      text-decoration-line: underline;
    }
  }
  .container-title {
    margin-top: 30px;
    text-align: left;
    &-index {
      margin-top: 15px;
      text-align: left;
    }
  }
  .subtitle {
    margin-top: 15px;
    font-weight: lighter;
    color: darkslategray;
    &-index {
      margin-top: 15px;
      font-weight: lighter;
      color: darkslategray;
    }
  }
  .value {
    border-top: 1px solid $grey;
    padding: 15px 0;
    .question {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0;
        font-size: 18px;
        font-weight: $font_medium;
        font-family: "stratos", sans-serif;
      }
    }

    .answer {
      margin-top: 10px;
      font-size: 15px;
      p {
        font-size: 15px;
        margin: 0;
      }
      ul > li {
        font-size: 15px;
      }
    }
    .answer {
      display: none;
    }
  }
}

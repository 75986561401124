@import "../variables";

.getting-started {
  display: flex;
  justify-content: center;

  ol {
    counter-reset: list-counter;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: grid;
      grid-template-columns: 55px auto;
      counter-increment: list-counter;
      margin-bottom: 30px;
      align-items: center;

      > div {
        text-align: left;
      }
    }

    li::before {
      font-family: "stratos", sans-serif;
      line-height: 1.7;
      font-size: 22px;
      margin-right: 15px;
      content: counter(list-counter);
      border: 2px solid $red;
      border-radius: 100%;
      height: 42px;
      width: 42px;
      z-index: 1;
    }
  }
}

.iframe-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.password-meter-wrap {
  margin-top: 8px;
  height: 5px;
  background-color: #ddd;
}

.password-meter-bar {
  width: 0;
  height: 100%;
  transition: width 400ms ease-in;
}

.password-meter-bar.level0 {
  width: 20%;
  background-color: #fc252c;
}
.password-meter-bar.level1 {
  width: 40%;
  background-color: #d1a01b;
}
.password-meter-bar.level2 {
  width: 60%;
  background-color: #ffc31f;
}
.password-meter-bar.level3 {
  width: 80%;
  background-color: #40b956;
}

.password-meter-bar.level4 {
  width: 100%;
  background-color: #4ad965;
}

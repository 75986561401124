@import "../_mixins/responsive";

// Images
.img-block {
  display: block;
}

.img-center {
  margin: 0 auto;
}

.img-circle {
  border-radius: 50%;
}

// Margins & Padding
@for $i from 0 through 100 {
  .mt-#{$i} {
    margin-top: #{$i}px;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }
  .pt-#{$i} {
    padding-top: #{$i}px;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}px;
  }

  .mr-#{$i} {
    margin-right: #{$i}px;
  }
  .ml-#{$i} {
    margin-left: #{$i}px;
  }
  .pr-#{$i} {
    padding-right: #{$i}px;
  }
  .pl-#{$i} {
    padding-left: #{$i}px;
  }

  $i: $i + 10;
}

// Font sizes
// Margins & Padding
@for $i from 10 through 50 {
  .fs-#{$i} {
    font-size: #{$i}px;
  }
  $i: $i + 10;
}

// Colors

a,
span,
p,
small,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @each $name, $color in $colors {
    &.#{$name} {
      color: $color;
    }
  }
}

// Nav tabs TODO: export in a dedicated file
.nav-tabs {
  border-bottom: 0;
  display: flex;
  justify-content: center;

  @include respond-to(xs) {
    display: block;
  }

  > li {
    padding: 0;
    a {
      color: $light_grey;
      border-bottom: 7px solid $light_grey;
      font-size: 18px;
      padding: 10px 0;
      font-weight: $font_regular;
      text-align: center;
      width: 170px;
      display: block;
      transition: border-color 0.4s;

      @include respond-to(sm) {
        width: 140px;
      }

      @include respond-to(xs) {
        width: 100%;
      }
    }
    margin: 0 15px;

    &:not(.active) {
      a {
        &:hover {
          border-color: $accent_color;
        }
      }
    }

    &.active {
      a {
        color: $black;
        border-color: $primary_color;
      }
    }
  }

  + .tab-content {
    display: block;
    margin-top: 50px;
  }
}

// Hidden class based on media queries
.hidden-xxs {
  @include respond-to(xxs) {
    display: none !important;
  }
}
.hidden-xs {
  @include respond-to(xs) {
    display: none !important;
  }
}
.hidden-sm {
  @include respond-to(sm) {
    display: none !important;
  }
}
.hidden-md {
  @include respond-to(md) {
    display: none !important;
  }
}
.hidden-lg {
  @include respond-to(lg) {
    display: none !important;
  }
}
.hidden-xlg {
  @include respond-to(xlg) {
    display: none !important;
  }
}

.hidden {
  display: none;
}

// Max width
@for $i from 0 through 100 {
  .mw-#{$i} {
    $width: $i + "%";
    max-width: #{$width};
    margin: 0 auto;

    @include respond-to(upto-sm) {
      max-width: 100%;
    }
  }
  $i: $i + 10;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

@include respond-to(upto-sm) {
  .text-left-sm {
    text-align: left !important;
  }
  .text-center-sm {
    text-align: center !important;
  }
  .text-right-sm {
    text-align: right !important;
  }
}

@include respond-to(upto-xs) {
  .text-left-xs {
    text-align: left !important;
  }
  .text-center-xs {
    text-align: center !important;
  }
  .text-right-xs {
    text-align: right !important;
  }
}

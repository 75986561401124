.grid {
  display: grid;

  &.grid-2-cols {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 30px;

    @include respond-to(upto-sm) {
      grid-template-columns: 1fr;
    }

    &.bordered {
      > div {
        &:not(:last-child) {
          border-right: 1px solid $grey;
        }

        @include respond-to(upto-sm) {
          border: none !important;
        }
      }
    }
  }

  &.grid-4-cols {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 0;

    @include respond-to(md) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    @include respond-to(sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include respond-to(xs) {
      grid-template-columns: 1fr;
    }

    > div {
      padding: 0 30px;
      @include respond-to(upto-sm) {
        padding: 20px 20px;
      }
      @include respond-to(xs) {
        padding: 20px 0;
      }
    }

    &.bordered {
      > div {
        &:not(:last-child) {
          border-right: 1px solid $grey;
        }

        @include respond-to(upto-sm) {
          border: none !important;
        }
      }
    }
  }
}

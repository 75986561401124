@import "../_mixins/responsive";

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "stratos", sans-serif;
}

h1,
.h1 {
  font-size: 42px;
}
h2,
.h2 {
  font-size: 34px;
}
h3,
.h3 {
  font-size: 28px;
}
h4,
.h4 {
  font-size: 24px;
}
h5,
.h5 {
  font-size: 20px;
  line-height: 1.4;
}
h6,
.h6 {
  font-size: 16px;
  line-height: 1.4;
}

@include respond-to(upto-sm) {
  h1,
  .h1 {
    font-size: 34px;
  }
  h2,
  .h2 {
    font-size: 26px;
  }
  h3,
  .h3 {
    font-size: 24px;
  }
  h4,
  .h4 {
    font-size: 20px;
  }
  h5,
  .h5 {
    font-size: 18px;
  }
  h6,
  .h6 {
    font-size: 16px;
  }
}

p {
  font-size: 16px;
}

.muted-text {
  color: $light_grey;
}

@import url("https://use.typekit.net/ozf0usq.css"); /* Stratos from Adobe */
@import "../fonts/Inter/inter.css"; /* Inter */

@import "variables";

// Partials
@import "_partials/utils";
@import "_partials/images";
@import "_partials/typography";
@import "_partials/layout";
@import "_partials/header";
@import "_partials/buttons";
@import "_partials/sections";
@import "_partials/footer";
@import "_partials/forms";
@import "_partials/checkboxes";
@import "_partials/grid";
@import "_partials/pricing_card";
@import "_partials/tables";
@import "_partials/tooltip";

// Blocks
@import "_blocks/partners";
@import "_blocks/societies";
@import "_blocks/reviews";
@import "_blocks/other_products";
@import "_blocks/faq";
@import "_blocks/team";
@import "_blocks/work_opportunities";
@import "_blocks/product_features";
@import "_blocks/signup";
@import "_blocks/sports";
@import "_blocks/milestones";
@import "_blocks/banner";

// Pages
@import "_pages/home";
@import "_pages/prices";

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: $font_default;
  font-size: 15px;

  a {
    color: $black;
    transition: color 0.3s;
    &:hover,
    &:active,
    &:focus {
      color: inherit;
      text-decoration: none;
    }
  }

  // Font weight
  .font-light {
    font-weight: $font_light !important;
  }
  .font-regular {
    font-weight: $font_regular !important;
  }
  .font-medium {
    font-weight: $font_medium !important;
  }
  .font-bold {
    font-weight: $font_bold !important;
  }

  .stratos {
    font-family: "stratos", sans-serif;
  }

  strong {
    font-weight: $font_bold;
  }
}

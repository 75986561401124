.form-group {
  margin: 20px 0;

  span {
    color: #777;
  }

  textarea,
  input {
    width: 100%;
    border: 1px solid $lighter_grey;
    outline: none !important;
    padding: 8px 12px;
    border-radius: 8px;
    background: $white;

    &:focus,
    &:active {
      border-color: $accent_color;
    }

    &.error {
      border-color: $red;
    }
  }

  textarea {
    min-height: 225px;
    max-height: 400px;

    &.webinar {
      min-height: 100px;
    }
  }

  select {
    width: 100%;
    border: none;
    outline: none !important;
    box-shadow: none !important;
    padding: 8px 0;
    color: #777;
    border-radius: 0;
    border-bottom: 2px solid $lighter_grey;
    &:focus,
    &:active {
      border-bottom-color: $accent_color;
      outline: none !important;
    }

    &.error {
      border-bottom-color: $primary_color;
    }
  }

  label.error {
    color: $primary_color;
    font-size: 12px;
  }
}

.select2-container {
  border: none;
  border-bottom: 2px solid $lighter_grey;
  border-radius: 0;
  background-color: transparent;

  &.invalid {
    border-color: $primary_color !important;
  }
}
.select2-container--default .select2-selection--multiple {
  border: none !important;
  border-radius: 0;
  background-color: transparent;
}

.select2-dropdown {
  border: none !important;
  border-radius: 0;
  background-color: #fff;
  border-bottom: 2px solid $lighter_grey;
}

.select2-container--default .select2-results__option--selectable {
  background-color: #fff;
}

.select2-container--default .select2-results > .select2-results__options {
  border: 1px solid $lighter_grey;
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: $lighter_grey;

  .club-option {
    background-color: $lighter_grey;
  }
}

input.select2-search__field {
  margin: 0 !important;
}

.select2-results__option {
  padding: 5px 10px;
  background: #fff;
  font-size: 14px;
}

.club-option {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  height: 45px;
  padding: 0;
  background: #fff;
  color: $black;
  img {
    width: 22px;
    margin-right: 20px;
  }

  &.club-option-selected {
    float: left;
    height: 30px;
    img {
      height: 25px;
      width: auto;
    }
  }
}

.select2-selection__choice {
  border: 0 !important;
  margin: 0 !important;
  background: 0 !important;

  > button {
    margin: 0 !important;
    border-right: 0 !important;
    margin-top: 3px !important;
    margin-left: 10px !important;
  }
}

.privacy-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $light_grey;
  font-size: 15px;
}

#contactFormSupport {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

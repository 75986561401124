.banner {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-to(upto-sm) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 20px;
    }
  }
}
